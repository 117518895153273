import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ukLocale from 'date-fns/locale/uk';

interface PropsType {
  id?: string;
  label?: string;
  value: Date | null;
  error?: boolean;
  required?: boolean;
  style?: React.CSSProperties | undefined;
  onChange: (value: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const BasicDatePicker: React.FC<PropsType> = (
  {
    id,
    value,
    required,
    onChange, label,
    style,
    error ,
    minDate = new Date('1900-01-01'),
    maxDate
  }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns as any} adapterLocale={ukLocale}>
      <DatePicker
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField id={id} style={style} fullWidth required={required} error={error} size="small" {...params} />
        )}
        minDate={minDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
};
