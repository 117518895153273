import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { StylesTable } from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { IProduct } from '../../store/products/types';
import { useSearch } from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import { BasicDatePicker, EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import { BoxModal } from '../modals';
import moment from 'moment';
import { GetActions } from '../../store/actions/actions';
import Button from '@mui/material/Button';

interface Column {
  id: 'userId' | 'appId' | 'actionId' | 'category1' | 'actionvalue' | 'adate';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
}

const sort = true;

const columns: Column[] = [
  { id: 'userId', label: 'Userid', align: 'center', sort },
  { id: 'appId', label: 'AppID', align: 'center', sort },
  { id: 'actionId', label: 'ActionID', align: 'center', sort },
  { id: 'category1', label: 'Category', align: 'center', sort },
  { id: 'actionvalue', label: 'Action value', align: 'center', sort },
  { id: 'adate', label: 'Дата створення події', align: 'center', width: '10%', sort },
];

type PropsType = {};

export const ListActions: React.FC<PropsType> = () => {
  const [bitrixid, setBitrixid] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [source, setSource] = React.useState('');
  const [category1, setCategory1] = React.useState('');
  const [category2, setCategory2] = React.useState('');
  const [actionId, setActionId] = React.useState('');
  const [actionvalue, setActionvalue] = React.useState('');
  const [adate, setAdate] = React.useState<Date | null>(null);

  const [isModal, setIsModal] = React.useState<{ value: string } | null>(null);

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');

  const { Actions } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const { loading, handleSearch } = useSearch({
    SearchData: GetActions,
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleChangeData<T>(value: T, query: string, callBack: (value: T) => void) {
    if (query === 'adate' && !moment(value as unknown as string).isValid()) return null;
    const data: any = {
      bitrixid,
      email,
      source,
      category1,
      category2,
      actionId,
      actionvalue,
      adate: moment(adate).isValid() ? adate : null,
    };
    callBack(value);
    delete data[query];
    handleSearch(value as unknown as string, query, data);
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!Actions.data) return null;
    if (Actions.data.pages >= page) {
      dispatch(
        GetActions.request({
          data: {
            bitrixid,
            email,
            source,
            category1,
            category2,
            actionId,
            actionvalue,
            adate,
            page,
          },
        })
      );
    }
  };

  const clearFilters = () => {
    setBitrixid('');
    setEmail('');
    setSource('');
    setCategory1('');
    setCategory2('');
    setActionId('');
    setActionvalue('');
    setAdate(null);
    dispatch(GetActions.request({ data: {} }));
  };

  return (
    <>
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="Userid"
          variant="outlined"
          value={bitrixid}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'bitrixid', setBitrixid);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Email"
          variant="outlined"
          value={email}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'email', setEmail);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="Source"
          variant="outlined"
          value={source}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'source', setSource);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="ActionId"
          variant="outlined"
          value={actionId}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'actionId', setActionId);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Category1"
          variant="outlined"
          value={category1}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'category1', setCategory1);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Category2"
          variant="outlined"
          value={category2}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'category2', setCategory2);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Action value"
          variant="outlined"
          value={actionvalue}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'actionvalue', setActionvalue);
          }}
        />
        <div className={classesTable.searchField} style={{ width: '210px' }}>
          <BasicDatePicker
            id="dateEnd"
            label="Дата створення події"
            value={adate}
            onChange={(value) => {
              handleChangeData(value, 'adate', setAdate);
            }}
          />
        </div>
        <Button variant="contained" color="primary" onClick={clearFilters} style={{ height: '40px' }}>
          Очистити фільтри
        </Button>
        {Actions.data && Actions.data.total && Actions.data.total ? (
          <div style={{ height: '40px' }} className={classesTable.textLength}>
            Знайдено {Actions.data.total} подій
          </div>
        ) : (
          ''
        )}
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 268px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns} />
          <TableBody>
            {Actions.data &&
              stableSort(Actions.data.actions, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.absnum}`}>
                    <TableCell align="center">
                      {row.userId}
                      <br />
                      {row.email || ''}
                    </TableCell>
                    <TableCell align="center">
                      {row.appId}
                      <br />
                      <IconButton aria-label="edit" onClick={() => setIsModal({ value: row.source })}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {row.actionId}
                      <br />
                      {row.action}
                    </TableCell>
                    <TableCell align="center">
                      {row.category1 ? `Category1: ${row.category1}` : ''}
                      <br />
                      {row.category2 ? `Category2: ${row.category2}` : ''}
                    </TableCell>
                    <TableCell align="center">{row.actionvalue}</TableCell>
                    <TableCell align="center">{moment(row.adate).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Actions.data ? Actions.data.pages : 0}
        page={Actions.data ? Actions.data.currentPage : 1}
        onChange={handleChangePage}
      />
      {isModal ? <BoxModal open={!!isModal} value={isModal.value} handleClose={() => setIsModal(null)} /> : null}
    </>
  );
};
