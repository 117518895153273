import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { URI } from '../../config';

interface Props {
  editorRef?: React.MutableRefObject<any | null>;
  initialValue?: string;
  placeholder?: string;
}

export const EditorDefault: React.FC<Props> = ({ editorRef, placeholder, initialValue }) => {
  const textConfig = {
    license_key: 'gpl',
    height: 350,
    width: '900px',
    plugins: 'preview link charmap image',
    menubar: false,
    toolbar:
      'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor backcolor | charmap | link image',
    paste_as_text: true,
    quickbars_selection_toolbar: false,
    placeholder,
  };
  return (
    <>
      {placeholder && (
        <h3
          style={{
            margin: '0 0 5px',
            fontWeight: 400,
            lineHeight: '1.334',
            letterSpacing: '0em',
          }}
        >
          {placeholder}
        </h3>
      )}
      <Editor
        onInit={(_evt, editor) => editorRef && (editorRef.current = editor)}
        tinymceScriptSrc={`${URI}/tinymce/tinymce.min.js`}
        initialValue={initialValue}
        init={textConfig as any}
      />
    </>
  );
};
