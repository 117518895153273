import { Actions, TDefRequest } from '../Actions';
import ActionTypes, {TProductActionsState, YearFiles} from './types';

const GetProductActionsAction = new Actions('GET_PRODUCTACTIONS', ActionTypes);
const GetProductActionsActionFiles = new Actions('GET_PRODUCTACTIONS_FILES', ActionTypes);
const DownloadProductActionsActionFile = new Actions('GET_PRODUCTACTIONS_DOWNLOAD_FILE', ActionTypes);
const GetProductActionsXLSXAction = new Actions('GET_PRODUCTACTIONS_XLSX', ActionTypes);

export interface TypeGetProductActionsR extends TDefRequest {
  data: {
    bitrixid?: string;
    actionId?: string;
    action_alias?: string;
    source?: string;
    param1?: string;
    param2?: string;
    param3?: string;
    param4?: string;
    param5?: string;
    param6?: string;
    adate?: Date | null;
    page?: number;
    startDate?: Date | null;
    endDate?: Date | null;
    year?: string;
  };
}

export interface TypeDownloadProductActionsFileR extends TDefRequest {
  path: string;
}

export const GetProductActions = {
  request: (payload: TypeGetProductActionsR) => GetProductActionsAction.request(payload),
  success: (payload: TProductActionsState['data']) => GetProductActionsAction.success(payload || {}),
  error: (message: string) => GetProductActionsAction.error(message),
};

export const GetProductActionsXLSX = {
  request: (payload: TypeGetProductActionsR) => GetProductActionsXLSXAction.request(payload),
  success: (resp: boolean) => GetProductActionsXLSXAction.success(resp),
  error: (message: string) => GetProductActionsXLSXAction.error(message),
};

export const GetProductActionsFiles = {
  request: (payload: TypeGetProductActionsR) => GetProductActionsActionFiles.request(payload),
  success: (payload: YearFiles) => GetProductActionsActionFiles.success(payload || {}),
  error: (message: string) => GetProductActionsActionFiles.error(message),
};

export const DownloadProductActionsFile = {
  request: (payload: TypeDownloadProductActionsFileR) => DownloadProductActionsActionFile.request(payload),
  success: (payload: boolean) => DownloadProductActionsActionFile.success(payload || {}),
  error: (message: string) => DownloadProductActionsActionFile.error(message),
};
